<template>
    <div class="button-container">
        <b-button variant="primary" size="sm" @click="openDetail('en')">            
            Generar PDF en Inglés
        </b-button> 
        <b-button variant="primary" size="sm" @click="openDetail('es')">            
            Generar PDF en Español
        </b-button> 
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'BtnsDetailsPdfLinks',
    props:{
		product:{
			type: Object,
			required: true
		},
        payloadRequest:{
			type: Object,
			required: true
		},
        queryParams: {
			type: Object,
            default: null
        }
	},
    computed:{
		...mapState('auth',['user']),
	
	},
    methods:{
        openDetail( lang = 'en'){
            const { type, idItem  } = this.payloadRequest
            const urlBackend = process.env.VUE_APP_BASE_URL_BACKEND.replace(/\/$/, '')
            const url = `${urlBackend}/Transactions/item/${type}/${idItem}/${lang}`

            let q = ''
            if( this.queryParams ){                
                const { dateRange, adults, children  } = this.queryParams
                const sectionChannel = this.user?.sectionCanal?.id || 34
                const user = this.user?.idUser || 1

                q = `?date-range=${dateRange}&adults=${adults}&children=${children}&section-channel=${sectionChannel}&usr=${user}`
            }
            window.open( `${url}${q}`, '_blank')
        }
    }


}

</script>

<style scoped>
.button-container {
    display: flex;
    flex-direction: column;
}

.button-container .btn {
    margin-bottom: 1rem;
}

.button-container .btn:last-child {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .button-container {
        flex-direction: row;
        justify-content: space-between;
    }
    .button-container .btn {
        margin-bottom: 0;
        margin-right: 1rem;
    }
    .button-container .btn:last-child {
        margin-right: 0;
    }
}

</style>