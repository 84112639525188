<template>
  <div>
    <div class="single-detail">
      <div>
        <span class="name-detail" :class="colorText">{{singleDetail.detaildisplay || singleDetail.detailDesc }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <div class="price-detail">
          <span variant="warning" :class="colorText">{{singleDetail.displayrates.currency}} {{formatAmount(singleDetail.displayrates.saleprice)}}</span>
        </div>
        <div>
          <button class="minus-qty" type="input" :disabled="singleDetail.qty == 0" @click="handlerQty('minus')" :class="colorText"> - </button>
          <span class="qty-number" :class="colorText">{{ singleDetail.qty }}</span>
          <button class="plus-qty" :class="colorText" type="input" @click="handlerQty('plus')" :disabled="singleDetail.qty == 1 && product.categoryName == 'Transfer'"> + </button>
        </div>
      </div>
      <div class="to-cart">
        <div>
          <b v-if="!!product.total && product.total > 0" class="total-product"> Total: {{product.showcurrency}} {{formatAmount(product.totaldivisa)}}</b>
        </div>
        <div>
          <b-button variant="primary"  @click="handlerAddToCart(product)" size="sm">
            <feather-icon icon="ShoppingCartIcon" size="16" />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { market } from '@/modules/shop/mixins/market'
import { globalCurrencyFormat, toJson } from '@/helpers/helpers'

export default {
  mixins: [ market ],
  props:{
    singleDetail: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    isSingle:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    ...mapState('appConfig',['layout']),
    colorText(){
      return this.layout.skin === 'dark' ? 'color-text-dark' : 'color-text-light'
    },
  },
  methods:{
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations('shop',['addProductInCart', 'addProductsFromPackage']),
    ...mapMutations('products',['resetQtyProductDetail']),
    handlerQty(operator){
      if(operator === 'plus'){
        this.singleDetail.qty += 1
      }

      if(operator === 'minus'){
        this.singleDetail.qty -= 1
      }

      if(this.isSingle){
        this.handlerQtyProductSingle(this.product, this.singleDetail)
      }

      if( !this.isSingle ){
        this.handlerQtyProduct(this.product, this.singleDetail)
      }
    },
    async handlerAddToCart(product){
      let item = toJson({...product})//spread
      const productsAddCart = this.generateProductsToAddCart(item) // desde el mixin
      //ciclo asyncrono para ir añadiendo al cart
      productsAddCart.forEach( async productCart => {
        const productFormatted = this.formatProductBeforeAddingToCart(productCart) //desde el mixin, formateo el item
        const productIsRepeated = this.existsInCart( productFormatted )
        if( !productIsRepeated ){ // si producto no se repite
          await this.manageAddProductInCart(productFormatted, productFormatted.categoryName) // agrego el producto formateado
        } else {
          await this.addOneMoreQty(productFormatted)
        }
      })
      this.resetQtyProductDetail( { idProduct:product.id } ) // reseteo los qty detail del producto
    },
    async addOneMoreQty(product){
      const { modelType, detailId, qty }  = product
      const prodInCart = this.cart.items.find( product => product.detailId === detailId && product.modelType === modelType )
      prodInCart.qty = prodInCart.qty + qty//aumento la cantidad
      await this.calculateTotalWithDiscounts()
      await this.qtyHasChanged(prodInCart) // desde el mixim market
    },
    formatAmount(amount){
      return globalCurrencyFormat(amount)
    },
  }
}
</script>

<style scoped>
.single-detail{
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  padding: .6rem;

}
.arrow-picker{
  display: flex;
  align-items: center;
}
.dropdown.b-dropdown{
  width: 100%;
}

.minus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;
  margin-inline-end: .5rem;
  font-size: 1.5rem;
}
.minus-qty:disabled{
  cursor: not-allowed;
}
.plus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;
  font-size: 1.5rem;

}
.minus-qty:hover, .plus-qty:hover{
  border: 1px solid #030303;

}
.plus-qty:disabled{
  cursor: not-allowed;
}
.qty-number{
  font-weight: 600;
  margin-inline-end: .5rem;
  color: black;
}
.name-detail{
  font-size: .9rem;
  font-weight: 600;
}
.price-detail{
  font-size: .8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.color-text-light{
  color: black!important;
}
.color-text-dark{
  color: white!important;
}
.to-cart{
  display: flex;
  justify-content: space-between;
  margin-block-start: .5rem;
}
.total-product {
  color: rgb(255, 254, 254);
  font-size: .8rem;
  background-color: #F56960;
  padding: 0.3rem 0.6rem;
  text-align: center;
  font-weight: 500;
}
</style>